import React, {useEffect, useState} from 'react'
import { Box, Heading } from 'theme-ui'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

/**
 * Shadow me to add your own content
 */

const styles = {
  box: {
    borderLeft: `5px solid`,
    borderLeftColor: `omegaLighter`,
    pl: 5,
    py: 2,
    my: 5
  },
  quote: {
    color: `omegaDark`,
    fontWeight: `body`
  },
  writer: {
    color: `omegaDark`,
    mb: 0
  }
}


const ContentRight = () => {

  const { title } = useSiteMetadata()

  const blQuotes = [
    {text: '“The only way to do great work is to love what you do. If you haven’t found it yet, keep looking. Don’t settle. As with all matters of the heart, you’ll know when you find it.”', author: 'Steve Jobs'},
    {text: '“If you want to live a happy life, tie it to a goal, not to people or things.”', author: 'Albert Einstein'},
    {text: '“You miss 100% of the shots you don’t take.”', author: 'Wayne Gretzky'},
    {text: '“Success is not final, failure is not fatal: it is the courage to continue that counts.”', author: 'Winston Churchill'},
    {text: '“The best way to predict the future is to create it.”', author: 'Abraham Lincoln'},
    {text: '“The only person you are destined to become is the person you decide to be.”', author: 'Ralph Waldo Emerson'},
    {text: '“Happiness is not something ready made. It comes from your own actions.”', author: 'Dalai Lama'},
    {text: '“In the end, we will remember not the words of our enemies, but the silence of our friends.”', author: 'Martin Luther King Jr.'},
    {text: '“Believe you can and you’re halfway there.”', author: 'Theodore Roosevelt'},
    {text: '“Don’t watch the clock; do what it does. Keep going.”', author: 'Sam Levenson'},
    {text: '“Success is stumbling from failure to failure with no loss of enthusiasm.”', author: 'Winston Churchill'},
    {text: '“It does not matter how slowly you go as long as you do not stop.”', author: 'Confucius'},
    {text: '“You are never too old to set another goal or to dream a new dream.”', author: 'C.S. Lewis'},
    {text: '“The greatest glory in living lies not in never falling, but in rising every time we fall.”', author: 'Nelson Mandela'},
    {text: '“If you don’t like something, change it. If you can’t change it, change your attitude.”', author: 'Maya Angelou'},
    {text: '“Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.”', author: 'Albert Schweitzer'},
    {text: '“It’s not the years in your life that count. It’s the life in your years.”', author: 'Abraham Lincoln'},
    {text: '“The only limit to our realization of tomorrow will be our doubts of today.”', author: 'Franklin D. Roosevelt'},
    {text: '“In three words I can sum up everything I’ve learned about life: it goes on.”', author: 'Robert Frost'},
    {text: '“The greatest glory in living lies not in never falling, but in rising every time we fall.”', author: 'Nelson Mandela'},
    {text: '“While one person hesitates because he feels inferior, the other is busy making mistakes and becoming superior.”', author: 'Henry C. Link'},
  ];

  const lrQuotes = [
    {text: '“Sunshine is delicious, rain is refreshing, wind braces us up, snow is exhilarating; there is really no such thing as bad weather, only different kinds of good weather.”', author: 'John Ruskin'},
    {text: '“There is no such thing as bad weather, only different kinds of good weather.”', author: 'John Ruskin'},
    {text: '“The sun did not shine. It was too wet to play. So we sat in the house. All that cold, cold, wet day.”', author: 'Dr. Seuss'},
    {text: '“Everybody talks about the weather, but nobody does anything about it.”', author: 'Mark Twain'},
    {text: '“Climate is what we expect, weather is what we get.”', author: 'Mark Twain'},
    {text: '“Weather is a great metaphor for life - sometimes it’s good, sometimes it’s bad, and there’s nothing much you can do about it but carry an umbrella.”', author: 'Terri Guillemets'},
    {text: '“The best thing one can do when it’s raining is to let it rain.”', author: 'Henry Wadsworth Longfellow'},
    {text: '“Rain is grace; rain is the sky descending to the earth; without rain, there would be no life.”', author: 'John Updike'},
    {text: '“Winter is nature’s way of saying, “Up yours.””', author: 'Robert Byrne'},
    {text: '“Weather forecast for tonight: dark.”', author: 'George Carlin'},
    {text: '“Weather is a great bluffer. I guess the same is true of our human society -- things can look dark, then a break shows in the clouds, and all is changed.”', author: 'E. B. White'},
    {text: '“The sun did not shine. It was too wet to play. So we sat in the house. All that cold, cold, wet day.”', author: 'Dr. Seuss'},
    {text: '“Rain showers my spirit and waters my soul.”', author: 'Emily Logan Decens'},
    {text: '“The heart of autumn must have broken here, and poured its treasure upon the leaves.”', author: 'Charlotte Fiske Bates'},
    {text: '“Rain hangs about the place, like a friendly ghost. If it’s not coming down in delicate droplets, then it’s in buckets; and if neither, it tends to lurk suspiciously in the atmosphere.”', author: 'Barbara Acton-Bond'},
    {text: '“Weather is a great metaphor for life - sometimes it’s good, sometimes it’s bad, and there’s nothing much you can do about it but carry an umbrella.”', author: 'Terri Guillemets'},
    {text: '“Wherever you go, no matter what the weather, always bring your own sunshine.”', author: 'Anthony J. D’Angelo'},
    {text: '“A lot of people like snow. I find it to be an unnecessary freezing of water.”', author: 'Carl Reiner'},
    {text: '“Life’s a beach, and then you fry.”', author: 'Author Unknown'}
  ];

  const vqQuotes = [
    {text: '“The universe is not only stranger than we imagine, it is stranger than we can imagine.”', author: 'J.B.S. Haldane'},
    {text: '“The UFO phenomenon simply does not behave like extraterrestrial visitors. It actually behaves like some kind of clandestine government plan, masquerading as an ET visitation.”', author: 'Jacques Vallee'},
    {text: '“Theosophy is who Theosophy does.”', author: 'Helena Blavatsky'},
    {text: '“We are not human beings having a spiritual experience. We are spiritual beings having a human experience.”', author: 'Pierre Teilhard de Chardin'},
    {text: '“It is by will alone I set my mind in motion.”', author: 'Frank Herbert'},
    {text: '“There are more things in heaven and earth, Horatio, than are dreamt of in your philosophy.”', author: 'William Shakespeare'},
    {text: '“The most merciful thing in the world, I think, is the inability of the human mind to correlate all its contents.”', author: 'H.P. Lovecraft'},
    {text: '“The cosmos is within us. We are made of star-stuff. We are a way for the universe to know itself.”', author: 'Carl Sagan'},
    {text: '“The possession of knowledge does not kill the sense of wonder and mystery. There is always more mystery.”', author: 'Anais Nin'},
    {text: '“The important thing is not to stop questioning. Curiosity has its own reason for existing.”', author: 'Albert Einstein'},
    {text: '“We live on a placid island of ignorance in the midst of black seas of infinity, and it was not meant that we should voyage far.”', author: 'H.P. Lovecraft'},
    {text: '“The mind is everything. What you think you become.”', author: 'Buddha'},
    {text: '“I want to believe.”', author: 'Fox Mulder (The X-Files)'},
    {text: '“What you call sanity, it’s just a prison in your minds that stops you from seeing that you’re just tiny little cogs in a giant absurd machine.”', author: 'Elliot Alderson (Mr. Robot)'},
    {text: '“There is nothing either good or bad, but thinking makes it so.”', author: 'William Shakespeare'},
    {text: '“Do what thou wilt shall be the whole of the Law.”', author: 'Aleister Crowley'},
    {text: '“The greatest secrets are always hidden in the most unlikely places. Those who don’t believe in magic will never find it.”', author: 'Roald Dahl'},
    {text: '“I have seen things that defy explanation, that scare me to this day. Do you know what I’m talking about?”', author: 'Dana Scully (The X-Files)'},
    {text: '“Believe nothing, no matter where you read it or who has said it, not even if I have said it, unless it agrees with your own reason and your own common sense.”', author: 'Buddha'},
    {text: '“The truth is out there.”', author: 'Fox Mulder (The X-Files)'},
  ];

  const elonMuskQuotes = [
    {text: '“Persistence is very important. You should not give up unless you are forced to give up.”', author: 'Elon Musk'},
    {text: '“If something is important enough, even if the odds are against you, you should still do it.”', author: 'Elon Musk'},
    {text: '“When something is important enough, you do it even if the odds are not in your favor.”', author: 'Elon Musk'},
    {text: '“I think it’s possible for ordinary people to choose to be extraordinary.”', author: 'Elon Musk'},
    {text: '“I would like to die on Mars. Just not on impact.”', author: 'Elon Musk'},
    {text: '“The first step is to establish that something is possible; then probability will occur.”', author: 'Elon Musk'},
    {text: '“It’s OK to have your eggs in one basket as long as you control what happens to that basket.”', author: 'Elon Musk'},
    {text: '“Failure is an option here. If things are not failing, you are not innovating enough.”', author: 'Elon Musk'},
    {text: '“Life is too short for long-term grudges.”', author: 'Elon Musk'},
    {text: '“I think that’s the single best piece of advice: constantly think about how you could be doing things better and questioning yourself.”', author: 'Elon Musk'},
    {text: '“Great companies are built on great products.”', author: 'Elon Musk'},
    {text: '“You shouldn’t do things differently just because they’re different. They need to be...better.”', author: 'Elon Musk'},
    {text: '“I don’t create companies for the sake of creating companies, but to get things done.”', author: 'Elon Musk'},
    {text: '“I could either watch it happen or be a part of it.”', author: 'Elon Musk'},
    {text: '“The future belongs to those who create it.”', author: 'Elon Musk'},
    {text: '“Some people don’t like change, but you need to embrace change if the alternative is disaster.”', author: 'Elon Musk'},
    {text: '“I think it is possible for ordinary people to choose to be extraordinary.”', author: 'Elon Musk'},
    {text: '“People work better when they know what the goal is and why. It is important that people look forward to coming to work in the morning and enjoy working.”', author: 'Elon Musk'},
    {text: '“I don’t spend my time pontificating about high-concept things; I spend my time solving engineering and manufacturing problems.”', author: 'Elon Musk'},
    {text: '“Brand is just a perception, and perception will match reality over time.”', author: 'Elon Musk'},
  ];

  const quotes =
      (title === 'VisionQuesters') ? vqQuotes :
      (title === 'LocalRadarWeather') ? lrQuotes :
      (title === 'ElonMuskHub') ? elonMuskQuotes :
      blQuotes;

  const [quote, setQuote] = useState({ text: '', author: '' });

  const selectRandomQuote = () => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
  }

  useEffect(() => {
    selectRandomQuote();
  }, []);

  return (<Box sx={styles.box}>
    <Heading variant='h3' sx={styles.quote}>
      {quote.text}
    </Heading>
    <Heading variant='h4' sx={styles.writer}>
      {quote.author}
    </Heading>
  </Box>)
}

export default ContentRight
