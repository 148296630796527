import React from 'react'
import { Box } from 'theme-ui'
import { FaArchive } from 'react-icons/fa'
import IconButton from '@components/IconButton'
import Section from '@components/Section'

const styles = {
  horizontal: {
    display: `flex`,
    flexWrap: `nowrap`,
    overflowX: `auto`,
    width: `auto`,
    scrollBehavior: `smooth`,
    m: -2,
    a: {
      flex: 1,
      minWidth: [`1/3`, `auto`],
      m: 2
    }
  },
  horizontalBigIcon: {
    display: `flex`,
    flexWrap: `nowrap`,
    overflowX: `auto`,
    width: `auto`,
    scrollBehavior: `smooth`,
    m: -2,
    a: {
      flex: 1,
      minWidth: [`auto`, `auto`],
      m: 2
    }
  }
}



const Categories = ({ variant, categories, cmStyle, ...props  }) => (
    <Section aside={variant === 'vertical'} title='Topics' {...props}>
    <Box id='catScroll' sx={styles[variant]}>
      {cmStyle === "a" && <IconButton
        key='1898'
        name='Horoscopes'
        to='/category/horoscopes/'
        iconPath='//images.ctfassets.net/nw4vv98ntlpz/547gK10gjk5IQ2j6wvXIic/cc33421570f8807ed1ee1d9bf79fe5b3/crystal-ball-future-svgrepo-com.svg'
        icon=''
        variant='horizontal' />
      }
      {cmStyle === "a" && <IconButton
        key='1398'
        name='Games'
        to='/games/'
        iconPath='//images.ctfassets.net/nw4vv98ntlpz/2ZA5HW30QKr8CrDhLLPaEk/bec537a59a18ffed2d67415906fb5976/games-svgrepo-com.svg'
        icon=''
        variant='horizontal' />
      }
      {cmStyle === "a" && <IconButton
        key='1889'
        name='Watch LiveTV'
        to='/tv'
        iconPath='//images.ctfassets.net/nw4vv98ntlpz/7zPtkFHkSSnLQkkIixQS1I/051d99f54456c3c567ed260085691157/television-svgrepo-com.svg'
        icon=''
        variant='horizontal'/>
      }
      {cmStyle === "a" && <IconButton
        key='1893'
        name='Weather'
        to='/weather'
        iconPath='//images.ctfassets.net/nw4vv98ntlpz/2ZA5HW30QKr8CrDhLLPaEk/7e1185b5825e2261d8b1aed41893972b/weather-symbol-4-svgrepo-com.svg'
        icon=''
        variant='horizontal' />
      }
      {
        cmStyle !== 'a' && categories &&
        categories.map(({ id, name, slug, totalCount, icon }) => {

          const buttonProps = {
            key: id,
            name,
            number: totalCount,
            to: slug,
            iconPath: icon,
            Icon: !icon && FaArchive,
            variant
          }

          return totalCount !== 0 && <IconButton {...buttonProps} />
        })}
    </Box>
  </Section>
)

export default Categories

Categories.defaultProps = {
  variant: 'vertical'
}
