import React from 'react'
import { Heading } from 'theme-ui'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import ContentRight from "./Hero.Content.Right";

/**
 * Shadow me to add your own content
 */

const styles = {
    heading: {
        span: {
            color: `omegaDark`
        }
    }
}

const ContentLeft = () => {
    const { title } = useSiteMetadata()

    const bestLifeText = {p1: 'Get Inspired to Achieve', p2: 'Enhanced Outcomes.'};
    const visionQuestText = {p1: 'Explore the Unknown', p2: '& Unlock Mysteries.'};
    const localRadarText = {p1: 'Weather Any Storm', p2: 'Be One Step Ahead'};
    const elonMuskText = {p1: 'Good Ideas Are Always Crazy.', p2: 'Until They\'re Not.'};

    const content =
        title === 'VisionQuesters' ? visionQuestText :
        title === 'LocalRadarWeather' ? localRadarText :
        title === 'ElonMuskHub' ? elonMuskText :
        bestLifeText;


    return (
        <Heading variant='h1' sx={styles.heading}>
            {content.p1}
            <br />
            <span>{content.p2}</span>
        </Heading>
    )
}

export default ContentLeft