import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import HeroComponent from '../components/Hero/Hero'
import WeatherComponent from '@widgets/HeroWeather'
import Categories from "@elegantstack/flow-ui-widgets/src/Categories";
import { useBlogCategories } from '@helpers-blog'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import {Link} from "gatsby";
import {Button} from "theme-ui";

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()
  const { title } = useSiteMetadata()

  const catTitle = (title === 'ElonMuskHub') ? "Current Ventures": "Trending Topics"


  return (
    <Layout {...props}>
      <Seo title='Home' />
        {(title === 'LocalRadarWeather') && <WeatherComponent />}
        {(title === 'VisionQuesters' || title === 'LocalRadarWeather' || title === 'ElonMuskHub' || title === 'RedRightReport') ? "" : (<Divider />)}
        {(title === 'VisionQuesters' || title === 'LocalRadarWeather' || title === 'ElonMuskHub' || title === 'RedRightReport') ? "" : (
            <Stack effectProps={{ effect: false }}>
                <Categories categories={categories} variant='horizontal' omitTitle cmStyle='a'/>
            </Stack>
        )}
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <CardList
          nodes={featuredPosts.nodes}
          limit={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'vertical-cover']}
          title="Editor's Picks"
          loading='eager'
          omitCategory
          omitDate
        />
      </Stack>
        <Divider space={2} />

            <Stack>
                <CardList
                    nodes={featuredPosts.nodes}
                    limit={6}
                    skip={3}
                    columns={[1, 2, 3]}
                    variant={['horizontal-md', 'horizontal-aside']}
                    loading='eager'
                />
            </Stack>
        <Divider />
        <Stack effectProps={{ effect: false }}>
            {(title === 'ElonMuskHub') ? (<Categories categories={categories} variant='horizontalBigIcon' title={catTitle} />) : (
                (title === 'RedRightReport') ? "" : (<Categories categories={categories} variant='horizontal' title={catTitle} />)
            )}
        </Stack>
        <Divider />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent />
      </Hero>
      <Divider />

        { posts.group.length &&
            posts.group.map((group, index) => (
                <React.Fragment key={`${group.categoryName}.list`}>
                    {group.categoryName === 'Trending Conservative News' ? (
                        <Stack
                            effectProps={{ effect: 'fadeInLeft' }}
                            title="Trending News"
                            titleLink={group.nodes[0].category.slug}
                        >
                            <Main>
                                {group.nodes.map((node) => (
                                    <CardList
                                        key={node.id}
                                        nodes={[node]}
                                        omitCategory='true'
                                        limit={100} // Set a high limit to show as many cards as possible
                                        variant={['horizontal-md', 'horizontal']}
                                    />
                                ))}
                            </Main>
                        </Stack>

                    ) : group.categoryName === 'Horoscopes' ? (
                        <Stack
                            effectProps={{ effect: 'fadeInLeft' }}
                            title={group.categoryName}
                            titleLink={group.nodes[0].category.slug}
                        >
                            <Main>
                                <CardList
                                    nodes={group.nodes}
                                    limit={2}
                                    variant={['horizontal-md', 'horizontal']}
                                />
                                <Divider space={2} />
                                <CardList
                                    nodes={group.nodes}
                                    limit={3}
                                    skip={2}
                                    variant={['horizontal-md', 'vertical']}
                                />
                            </Main>
                        </Stack>
                    ) : group.categoryName === 'BestLife' ? (
                        <Stack
                            effectProps={{ effect: 'fadeInLeft' }}
                            title={group.categoryName}
                            titleLink={group.nodes[0].category.slug}
                        >
                            <Main>
                                <CardList
                                    nodes={group.nodes}
                                    limit={2}
                                    variant={['horizontal-md', 'horizontal']}
                                />
                            </Main>
                            <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
                                <CardList
                                    nodes={group.nodes}
                                    limit={1}
                                    skip={2}
                                    variant={['horizontal-md', 'vertical']}
                                />
                            </Sidebar>
                        </Stack>
                    ) : (index % 2 === 0) ? (
                        <Stack
                            title={group.categoryName}
                            titleLink={group.nodes[0].category.slug}
                        >
                            <Main>
                                <CardList
                                    nodes={group.nodes}
                                    limit={2}
                                    variant={['horizontal-md', 'horizontal']}
                                />
                            </Main>
                            <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
                                <CardList
                                    nodes={group.nodes}
                                    limit={1}
                                    skip={2}
                                    variant={['horizontal-md', 'vertical']}
                                />
                            </Sidebar>
                        </Stack>
                    ) : (
                        <Stack
                            effectProps={{ effect: 'fadeInLeft' }}
                            title={group.categoryName}
                            titleLink={group.nodes[0].category.slug}
                        >
                            <Main>
                                <CardList
                                    nodes={group.nodes}
                                    limit={1}
                                    variant={['horizontal-md', 'horizontal']}
                                />
                                <Divider space={2} />
                                <CardList
                                    nodes={group.nodes}
                                    limit={2}
                                    skip={2}
                                    columns={[1, 2]}
                                    variant={['horizontal-md', 'horizontal-aside']}
                                    omitCategory
                                />
                                <Divider space={2} />
                                <CardList
                                    nodes={group.nodes}
                                    limit={2}
                                    skip={4}
                                    columns={[1, 2]}
                                    variant={['horizontal-md', 'horizontal-aside']}
                                    omitCategory
                                />
                            </Main>
                            <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
                                <CardList
                                nodes={group.nodes}
                                limit={1}
                                skip={1}
                                variant={['horizontal-md', 'vertical']}
                                />
                            </Sidebar>
                            </Stack>
                            )}
                            {index !== posts.group.length - 1 && <Divider />}
                            </React.Fragment>
                            ))}
                            <Divider space={5} />


        {(title === 'RedRightReport') ? ( <Stack>
            <Main><Button as={Link} to='/category/trending-conservative-news/' variant='primary' aria-label='View More'>View More</Button></Main>
        </Stack>) : ""}


      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
